html,
body,
#root {
  height: 100%;
  min-width: 650px; /* 1024px; */
}

body,
input {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #f7f7f7;
  color: #333333;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}

a:hover {
  color: inherit;
}

* {
  box-sizing: border-box;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: none;
}

.icon {
  font-size: 30px;
}

.show-mobile {
  display: none;
}

@media (max-width: 1400px) {
  .hide-mobile {
    display: none !important;
  }
}

@media (min-width: 1400px) {
  .show-mobile {
    display: none !important;
  }
}

.ReactVirtualized__Grid,
.ReactVirtualized__Table__Grid {
  outline: none;
}

.ReactVirtualized__Table__row:focus {
  outline: none;
}

.intercom-lightweight-app-launcher {
  bottom: 14px !important;
}
